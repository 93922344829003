import React, { useState, useEffect } from 'react';
import TaskForm from './TaskForm';
import '../../assets/stylesheets/components/_task-scheduler.scss';
import { initializeTaskData, updateHiddenFields } from './utils/taskSchedulerUtils';

const TaskScheduler = ({
  taskGenerator,
  shifts,
  users,
  inmates,
  recurringTypes,
  taskTypes,
  sharedTasks,
}) => {
  const [taskData, setTaskData] = useState(initializeTaskData());

  useEffect(() => {
    setTaskData((prev) => ({
      ...prev,
      name: taskGenerator.name || '',
      description: taskGenerator.description || '',
    }));

    Object.entries(taskData).forEach(([key, value]) => {
      updateHiddenFields(key, value);
    });
  }, [taskGenerator]);

  const handleChange = (key, value) => {
    setTaskData((prev) => ({ ...prev, [key]: value }));
    updateHiddenFields(key, value);
  };

  return (
    <div>
        <h2 className='title'>New Task</h2>
        <TaskForm
          taskData={taskData}
          onChange={handleChange}
          shifts={shifts}
          users={users}
          inmates={inmates}
          recurringTypes={recurringTypes}
          taskTypes={taskTypes}
          sharedTasks={sharedTasks}
        />
    </div>
  );
};

export default TaskScheduler;
