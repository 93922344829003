import React from 'react';
import '../../../../assets/stylesheets/components/_widget-header.scss';

const Header = React.forwardRef(
  (
    {
      setModal,
      title,
      pastDue,
      approveAll = false,
      confirmAll = false,
      newEntry = false,
      seeAll = false,
      toggleGreenRecords,
    },
    ref
  ) => {
    const buttons = [
      approveAll && {
        text: 'Approve All',
        href: '/complete_all_head_count_modal',
        className: 'approve-all',
        data: { toggle: 'head-count-modal', target: 'head-count-modal', remote: 'true' },
      },

      newEntry && {
        text: '+ New Entry',
        href: '/new_log_entry_from_modal',
        className: 'approve-all',
        data: { toggle: 'log-entry-modal', target: 'log-entry-modal', remote: 'true' },
      },
    ].filter(Boolean);
    const links = [
      confirmAll && {
        text: 'Confirm All Missed',
        href: '/confirm_all_dispensations',
        className: 'approve-all',
        confirm: 'Are you sure you want to confirm all dispensations as missed?',
      },
      seeAll  && {
        text: 'Show All',
        href: '/hunger_strikes',
        className: 'approve-all',
      },
    ].filter(Boolean);

    return (
      <div className='container-header' ref={ref}>
        <div />

        <div className='title-container'>
          <h2
            className='widget-title'
            style={{
              marginBottom: '0',
              whiteSpace: title === 'KEEP SEPARATE ALERTS' ? 'nowrap' : 'normal',
            }}
          >
            {title}
          </h2>

          {pastDue != null && pastDue > 0 && (
            <h2 className='past-due-title bold'>{`${pastDue} - PAST DUE`}</h2>
          )}


        </div>

        <div className='button-container'>
        {links.length > 0 && (
            <>
              {links.map(({ text, href, className, confirm }, index) => (
                <a
                  key={index}
                  href={href}
                  className={className}
                  {...(confirm && { 'data-confirm': confirm })}
                >
                  <span>{text}</span>
                </a>
              ))}
            </>
          )}

          {toggleGreenRecords && (
            <button
              onClick={toggleGreenRecords}
              className='toggle-button'
              style={{
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                padding: '8px 16px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              SHOW ALL
            </button>
          )}

          {buttons.length > 0 && (
            <>
              {buttons.map(({ text, href, className, data, confirm }, index) => (
                <a
                  key={index}
                  onClick={() => setModal(true)}
                  href={href}
                  className={className}
                  {...(confirm && { 'data-confirm': confirm })}
                  {...(data && {
                    'data-toggle': data.toggle,
                    'data-target': data.target,
                    'data-remote': data.remote,
                  })}
                >
                  <span>{text}</span>
                </a>
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
);

export default Header;
